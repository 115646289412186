<template>
  <div class="carValuationRecord">
    <v-data-table
      :headers="headers"
      :items="carValuationRecord"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @update:options="sortItems"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.createTime }}</td>
          <td>{{ row.item.nricNumber }}</td>
          <td>{{ row.item.idTypeName }}</td>
          <td>{{ row.item.ownerName }}</td>
          <td>{{ row.item.carPlateNumber }}</td>
          <td>{{ row.item.contactNumber }}</td>
          <td>{{ row.item.email }}</td>
          <td>{{ row.item.hearAboutUs }}</td>
          <td>{{ row.item.promoCode }}</td>
          <td>{{ row.item.mileage }}</td>
          <td>{{ row.item.carModelName }}</td>
          <td>{{ row.item.valuationResult }}</td>
          <td>{{ row.item.regDate }}</td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import getAxios from "../../../services/axios-get";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      type: null,
      alertMsg: null,
      pageCount: 0,
      imgFile: null,
      editImgFile: null,
      imgUrl: null,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      nameSearch: "",
      total: 0,
      dialog: false,
      editDialog: false,
      deleteDialog: false,
      token: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Created Time", value: "createTime" },
        { text: "NRIC", value: "nricNumber" },
        { text: "ID Type", value: "idTypeName" },
        { text: "Name", value: "ownerName" },
        { text: "Car Plate", value: "carPlateNumber" },
        { text: "Contact", value: "contactNumber" },
        { text: "Email", value: "email" },
        { text: "Hear About Us", value: "hearAboutUs" },
        { text: "Promocode", value: "promoCode" },
        { text: "Mileage", value: "mileage" },
        { text: "Car Model", value: "carModelName" },
        { text: "Valuation", value: "valuationResult" },
        { text: "Reg Date", value: "regDate" },
      ],
      carValuationRecord: [],
      sortValue: null,
      sortBy: null,
      sortDesc: null,
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchCarValuationRecord();
  },
  methods: {
    sortItems({ sortBy, sortDesc }) {
      let columnName = sortBy[0];
      if (columnName && sortDesc[0] === true) {
        this.sortValue = columnName + ",desc";
      } else if (columnName && sortDesc[0] === false) {
        this.sortValue = columnName;
      } else {
        this.sortValue = null;
      }
      this.fetchCarValuationRecord();
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchCarValuationRecord();
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchCarValuationRecord();
    },
    handlePageSizeChange(newPageSize) {
      this.pagination.limit = newPageSize;
      const newPage = this.total / newPageSize;
      if (this.pagination.page > newPage) {
        this.pagination.page = Math.ceil(newPage);
      }
      this.fetchCarValuationRecord();
    },
    fetchCarValuationRecord() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.nameSearch,
        sort: self.sortValue,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/carValuationRecord`,
        params,
        self.token
      )
        .then(res => {
          if (res.data) {
            console.log(res.data.data.content);
            self.carValuationRecord = res.data.data.content;
            self.pagination.noOfPage = res.data.data.totalPages;
            self.carValuationRecord.forEach(x => {
              x.createTime = this.formatDate(x.createTime);
            });
          }
        })
        .catch(function(error) {
          self.showAlert("error", error);
        });
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
    formatDate(datex) {
      let date = new Date(datex);
      let formatted_date =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes();
      return formatted_date;
    },
  },
};
</script>
<style>
.plusButton {
  margin: 50px;
}
</style>
